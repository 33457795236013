export default {
    sv: {
        login: {
            siths: "Logga in med SITHS-kort",
            text: "Logga in för att se patienter"
        },
        button: {
            menu: "Meny"
        },
        patientList: {
            days: {
                1: "Måndagar",
                2: "Tisdagar",
                3: "Onsdagar",
                4: "Torsdagar",
                5: "Fredagar",
                6: "Lördagar",
                7: "Söndagar",
            }
        },
        organisations: {
            org: "Organisationer",
            id: "ID",
            name: "Namn",
            apikey: "API-nyckel",
            neworg: "Skapa ny organisation",
            save: "Spara"
        },
        overview: {
            baro: "Känslobarometern",
            trend: "Trend",
            weeklycard: "Veckokort",
            print: "Skriv ut",
            week: "Vecka",
            weekQuestion: "Hur stark är din önskan att",
            before: "Terapeutfråga i början av varje session",
            after: "Terapeutfråga i slutet av varje session",
            hurtYourself: "Skada dig själv?",
            quit: "Avsluta terapin?",
            top: "Tillbaka till toppen av sidan",
            treatmentStart: "från behandlingsstart",
            lastSave: "Senast sparat",
            saveError: "Fel vid sparning"
        },
        header: {
            loggedin: "Inloggad som",
            menu: "Meny",
            patientlist: "Patientlista",
            userlist: "Användarlista",
            organisations: "Organisationer",
            logout: "Logga ut"
        },
        patientlist: {
            list: "Patientlista",
            baro: "Känslobarometern",
            searchpatient: "Sök patient",
            name: "Namn",
            meetingtime: "Mötestid",
            newpatient: "Registrera ny patient",
            therapist: "Behandlare"
        },
        graph: {
            comment: "Kommentar",
            day: "Dag",
            yes: "Ja",
            no: "Nej",
            category: {
                POSITIVE: "Positiva beteenden",
                LIFEQUALITY: "Livskvalitetstörande beteenden",
                PROBLEM: "Problembeteenden",
                THERAPY: "Terapistörande beteenden"
            },
            trend: {
                1: "Glädje",
                2: "Rädsla/oro",
                3: "Skam",
                4: "Ilska",
                5: "Ledsenhet",
                15: "Hur många timmar har jag sovit senaste dygnet?",
                6: "Självmordstankar",
                7: "Impuls",
                8: "Handling?",
                9: "Annat impulsivt eller destruktivt beteende",
                10: "Har du tagit medicin?",
                11: "Har du använt droger inkl. alkohol?",
                12: "Har du gjort något som ger en känslan av kompetens idag?",
                13: "Har du använt färdighet idag?",
                14: "Övrigt"
            }
        },
        users: {
            id: "ID",
            name: "Namn",
            title: "Titel",
            phone: "Mobilnummer",
            organisation: "Organisation",
            hsaid: "HSAID",
            newuser: "Registrera ny användare",
            firstname: "Förnamn",
            lastname: "Efternamn",
            role: "Roll",
            remove: "Ta bort",
            save: "Spara",
            userlist: "Användarlista"
        },
        patient: {
            firstname: "Förnamn",
            lastname: "Efternamn",
            phone: "Mobilnummer",
            nationalid: "Personnummer",
            meetingtime: "Mötestid",
            dayofweek: "Mötesdag",
            monday: "Måndag",
            tuesday: "Tisdag",
            wednesday: "Onsdag",
            thursday: "Torsdag",
            friday: "Fredag",
            saturday: "Lördag",
            sunday: "Söndag",
            save: "Spara",
            remove: "Ta bort",
            therapist: "Behandlare",
            patientType: "DBT-patient:"
        },
        pdf: {
            week: "Vecka",
            day: "Dag",
            other: "Övrigt",
            comments: "Kommentarer"
        }
    }
}