<template>
  <div class="w-full bg-base-header flex justify-between p-2 items-center z-50">
    <div class="flex flex-col items-center ml-6" @click="toggleSidebar()">
      <button>
        <img class="w-7" src="@/assets/hamburger.svg" />
      </button>
      <span class="text-xs">{{ $t('header.menu') }}</span>
    </div>
    <div class="mr-6 text-right">
      <div class="text-base-gray-text">{{ $t('header.loggedin') }}:</div>
      <div class="font-semibold">{{ userFullname }} ({{ $store.getters.userData.organisationName }})</div>
    </div>
    <div
      v-if="showMenu"
      class="absolute w-64 bg-white shadow-md z-50"
      id="menu"
      style="top: 65px;"
      @mouseleave="closeSidebar()"
      @mouseenter="stop()"
    >
      <ul>
        <router-link :to="{ name: 'list' }">
          <li
            class="p-2 hover:bg-gray-200"
            :class="isCurrentRoute('list')"
          >{{ $t('header.patientlist') }}</li>
        </router-link>
        <router-link
          v-if="$store.getters.isAdmin || $store.getters.isLocalAdmin"
          :to="{ name: 'users' }"
        >
          <li
            class="p-2 hover:bg-gray-200"
            :class="isCurrentRoute('users')"
          >{{ $t('header.userlist') }}</li>
        </router-link>
        <router-link v-if="$store.getters.isAdmin" :to="{ name: 'organisations' }">
          <li
            class="p-2 hover:bg-gray-200"
            :class="isCurrentRoute('organisations')"
          >{{ $t('header.organisations') }}</li>
        </router-link>
        <router-link :to="{ name: 'logout' }">
          <li class="p-2 hover:bg-gray-200">{{ $t('header.logout') }}</li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      timer: null,
    };
  },
  watch: {
    $route() {
      this.$store.dispatch("closeSidebar");
    },
  },
  methods: {
    toggleSidebar() {
      clearTimeout(this.timer);
      this.$store.dispatch("toggleSidebar");
      this.closeSidebar();
    },
    closeSidebar() {
      this.timer = setTimeout(() => {
        this.$store.dispatch("closeSidebar");
      }, 10000);
    },
    stop() {
      clearTimeout(this.timer);
    },
    isCurrentRoute(to) {
      return this.$route.name === to ? "selected" : "";
    },
  },
  computed: {
    userFullname() {
      return (
        this.$store.getters.userData.firstname +
        " " +
        this.$store.getters.userData.lastname
      );
    },
    showMenu() {
      return this.$store.getters.isSidebarOpen;
    },
  },
};
</script>

<style scoped>
.selected {
  background: linear-gradient(
    90deg,
    rgb(248, 248, 255) 96%,
    rgba(204, 10, 122, 1) 96%
  );
}
</style>