<template>
  <div class="flex flex-col justify-start items-center h-screen bg-white">
    <img class="w-64 m-4" src="@/assets/images/kanslobarometern.png" />
    <button
      @click="loginSiths"
      class="bg-green-500 py-2 px-8 text-white text-lg rounded-lg m-4"
    >{{ $t('login.siths') }}</button>
    <p>{{ $t('login.text') }}</p>
  </div>
</template>

<script>
export default {
  name: "Login",
  methods: {
    loginSiths() {
      this.$store
        .dispatch("loginSiths")
        .then((data) => {
          window.location = data.redirectUrl;
        })
        .catch(() => {});
    },
  },
};
</script>