import { apiPostRequest, apiGetRequest } from "./api"; // eslint-disable-line

export default {
    state: {
        patientList: [],
        patientWeek: [],
        overviewData: [],
        trend: [],
        patient: [],
        pdfWeek: []
    },
    mutations: {
        setPatientList(state, data) {
            state.patientList = data;
        },
        setPatientWeek(state, data) {
            state.patientWeek = data;
        },
        setOverviewData(state, data) {
            state.overviewData = data;
        },
        setTrend(state, data) {
            state.trend = data;
        },
        setPatient(state, data) {
            state.patient = data;
        },
        setPdfWeek(state, data) {
            state.pdfWeek = data;
        }
    },
    actions: {
        async loadPatientList({ commit }) {
            await apiGetRequest(`list/patients`).then(data => {
                commit("setPatientList", data);
            });
        },
        async updatePatient({ commit }, data) { // eslint-disable-line
            await apiPostRequest(`update/patient`, data).then(data => {
                return data;
            });
        },
        async loadWeek({ commit }, params) { // eslint-disable-line
            await apiGetRequest(`card/answers/patient/week/` + params.join("/")).then(data => {
                commit('setPatientWeek', data);
            });
        },
        async loadData({ commit }, params) {
            await apiGetRequest(`period/therapy/` + params.join("/")).then(data => {
                commit('setOverviewData', data);
            })
        },
        async saveTherapy({ commit }, data) { // eslint-disable-line
            await apiPostRequest(`period/therapy`, data).then((data) => {
                return data;
            });
        },
        async loadTrend({ commit }, data) {
            await apiGetRequest(`period/trend/` + data).then((data) => {
                commit('setTrend', data);
            });
        },
        async loadPatient({ commit }, data) {
            await apiGetRequest(`patients/` + data).then((data) => {
                commit(`setPatient`, data);
            });
        },
        async searchPatientList({ commit }, data) {
            await apiGetRequest(`list/patients/search/` + encodeURI(data)).then((data) => {
                commit(`setPatientList`, data);
            });
        },
        async loadPdfWeek({ commit }, params) {
            await apiGetRequest(`pdf/week/` + params.join("/")).then(data => {
                commit("setPdfWeek", data);
            });
        },
        async removePatient({ commit }, id) { // eslint-disable-line
            await apiPostRequest(`remove/patient/${id}`).then(data => {
                return data;
            });
        }
    },
    getters: {
        getPatientList: state => state.patientList,
        getPatientWeek: state => state.patientWeek,
        getOverviewData: state => state.overviewData,
        getTrend: state => state.trend,
        getPatient: state => state.patient,
        pdfWeek: state => state.pdfWeek
    }
}