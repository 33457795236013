<template>
  <div class="w-full bg-light-back px-2 sm:px-12 pb-12">
    <div class="py-2">
      <p class="font-bold text-2xl -mb-3">{{ $t('organisations.org') }}</p>
      <p class="text-2xl text-base-gray-text"></p>
    </div>
    <div class="w-full p-2 bg-white">
      <div class="my-2 flex justify-end p-4">
        <button
          class="bg-green-500 px-8 py-2 rounded-lg text-white"
          @click="newOrg()"
        >{{ $t('organisations.neworg') }}</button>
      </div>
      <div class="p-4">
        <table v-if="loaded" class="w-full text-left">
          <tr class="text-base-gray-text border-b-2 border-pink-600">
            <th>{{ $t('organisations.id') }}</th>
            <th>{{ $t('organisations.name') }}</th>
            <th>{{ $t('organisations.apikey') }}</th>
          </tr>
          <tr
            v-for="(org, index) in organisations"
            :key="index"
            class="border-b-2 cursor-pointer hover:bg-gray-100"
          >
            <td>{{ org.id }}</td>
            <td>{{ org.name }}</td>
            <td>{{ org.api_key }}</td>
          </tr>
        </table>
        <div v-else class="flex justify-center">
          <font-awesome-icon icon="circle-notch" class="m-2 text-blue" spin size="2x" />
        </div>
      </div>
    </div>
    <modal height="auto" :scrollable="true" name="createOrg">
      <CreateOrg @loadOrganisations="loadOrganisations"/>
    </modal>
  </div>
</template>
<script>
import CreateOrg from "@/components/CreateOrg.vue";
export default {
  name: "AdminOrg",
  components: {
    CreateOrg
  },
  data() {
    return {
      organisations: null,
      loaded: false
    }
  },
  created() {
    this.loadOrganisations();
  },
  methods: {
    newOrg() {
      this.$modal.show("createOrg");
    },
    loadOrganisations() {
      this.$store.dispatch("loadOrganisations").then(() => {
        this.organisations = this.$store.getters.organisationList;
        this.loaded = true;
      });
    }
  },
};
</script>