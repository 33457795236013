<template>
    <div>
        <div class="bg-white p-4">
            <div class="flex items-center">
                <label for="name" class="font-bold">{{ $t('organisations.name') }}:</label>
                <input type="text" class="border-b-2 m-1 w-full" id="name" v-model="org.name" />
            </div>
            <div class="flex justify-center mt-8">
                <button class="bg-green-600 text-white rounded-lg px-6 py-2" @click="createOrg()" >{{ $t('organisations.save') }}</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CreateOrg",
    data() {
        return {
            org: {
                name: null
            },
        }
    },
    methods: {
        createOrg() {
            if(this.org.name) {
                this.$store.dispatch("createOrg", this.org).then(() => {
                    this.$modal.hide("createOrg");
                    this.$emit("loadOrganisations");
                }).catch(() => {
                    alert("Couldn't create organisation");
                });
            }
        }
    },
}
</script>