<template>
  <div class="flex flex-col p-8 bg-base-back">
    <div class="bg-white p-4" v-if="loaded">
      <div class="flex items-center">
        <label for="firstName" class="font-bold"
          >{{ $t("patient.firstname") }}:</label
        >
        <input
          type="text"
          class="border-b-2 m-1 w-full"
          id="firstName"
          v-model="patient.firstName"
        />
      </div>
      <div class="flex items-center">
        <label for="lastName" class="font-bold"
          >{{ $t("patient.lastname") }}:</label
        >
        <input
          type="text"
          class="border-b-2 m-1 w-full"
          id="lastName"
          v-model="patient.lastName"
        />
      </div>
      <div class="flex items-center">
        <label for="mobile" class="font-bold">{{ $t("patient.phone") }}:</label>
        <input
          type="tel"
          class="border-b-2 m-1 w-full"
          id="mobile"
          v-model="patient.mobile"
        />
      </div>
      <div class="flex items-center">
        <label for="nationalId" class="font-bold"
          >{{ $t("patient.nationalid") }}:</label
        >
        <input
          type="text"
          class="border-b-2 m-1 w-full"
          id="nationalId"
          v-model="patient.nationalId"
        />
      </div>
      <div class="flex items-center">
        <label for="meetingTime" class="font-bold"
          >{{ $t("patient.meetingtime") }}:</label
        >
        <input
          type="time"
          class="border-b-2 m-1 w-full"
          id="meetingTime"
          v-model="patient.meetingTime"
        />
      </div>
      <div class="flex items-center">
        <label for="meetingDay" class="font-bold"
          >{{ $t("patient.dayofweek") }}:</label
        >
        <select
          class="border-b-2 m-1 w-full"
          id="meetingDay"
          v-model="patient.dayOfWeek"
        >
          <option value="1">{{ $t("patient.monday") }}</option>
          <option value="2">{{ $t("patient.tuesday") }}</option>
          <option value="3">{{ $t("patient.wednesday") }}</option>
          <option value="4">{{ $t("patient.thursday") }}</option>
          <option value="5">{{ $t("patient.friday") }}</option>
          <option value="6">{{ $t("patient.saturday") }}</option>
          <option value="7">{{ $t("patient.sunday") }}</option>
        </select>
      </div>
      <div class="flex items-center">
        <label for="meetingDay" class="font-bold"
          >{{ $t("patient.therapist") }}:</label
        >
        <select
          class="border-b-2 m-1 w-full"
          id="meetingDay"
          v-model="patient.user"
        >
          <option
            v-for="(user, index) in availableUsers"
            :key="index"
            :value="user.id"
            >{{ user.first_name }} {{ user.last_name }}</option
          >
        </select>
      </div>
      <div class="flex items-center justify-between">
        <label class="font-bold">{{ $t("patient.patientType") }}</label>
        <div class="m-1 flex-1 flex items-baseline">
          <input
            type="checkbox"
            :true-value="1"
            :false-value="null"
            id="patientType"
            class="cursor-pointer"
            v-model="patient.type"
          />
        </div>
      </div>
      <div>
        <component
          :is="'BaseTextEditor'"
          :text="patient.contactInfo"
          v-bind:value.sync="patient.contactInfo"
        />
      </div>
      <div class="flex justify-center mt-8">
        <button
          class="bg-green-600 text-white rounded-lg px-6 py-2"
          @click="updatePatient()"
        >
          {{ $t("patient.save") }}
        </button>
        <button
          v-if="this.patient.id"
          class="bg-red-600 text-white rounded-lg px-6 py-2 mx-2"
          @click="removePatient()"
        >
          {{ $t("patient.remove") }}
        </button>
      </div>
    </div>
    <div v-else class="flex justify-center">
      <font-awesome-icon
        icon="circle-notch"
        class="m-2 text-blue"
        spin
        size="2x"
      />
    </div>
  </div>
</template>

<script>
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import Personnummer from "personnummer";
export default {
  name: "NewPatient",
  props: {
    patientId: Number,
    availableUsers: Array,
  },
  data() {
    return {
      loaded: false,
      textarea: false,
      patient: {
        firstName: "",
        lastName: "",
        mobile: null,
        nationalId: "",
        meetingTime: "",
        dayOfWeek: null,
        id: null,
        user: null,
        text: "",
        contactInfo: null,
        type: null,
      },
    };
  },
  created() {
    if (this.patientId != null) {
      this.$store.dispatch("loadPatient", this.patientId).then(() => {
        this.loaded = true;
        this.patient = this.$store.getters.getPatient;
      });
    } else {
      this.loaded = true;
    }
  },
  methods: {
    updatePatient() {
      let errors = this.validatePatient();
      if (errors.length > 0) {
        alert(errors.join("\n"));
      } else {
        this.$store
          .dispatch("updatePatient", this.patient)
          .then(() => {
            this.$modal.hide("editPatient");
            this.$emit("loadPatientList");
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      }
    },
    removePatient() {
      if (
        confirm(
          "Är du säker på att du vill ta bort patient med id: " + this.patientId
        )
      ) {
        this.$store.dispatch("removePatient", this.patientId).then(() => {
          this.$modal.hide("editPatient");
          this.$emit("loadPatientList");
        });
      }
    },
    validatePatient() {
      let errors = [];
      if (!this.patient.firstName) {
        errors.push("Förnamn saknas");
      }
      if (!this.patient.lastName) {
        errors.push("Efternamn saknas");
      }
      let valid = this.validateNumber();
      if (!valid) errors.push("Felaktigt telefonnummer");
      if (
        !this.patient.nationalId ||
        !Personnummer.valid(this.patient.nationalId)
      ) {
        errors.push("Felaktigt personnummer");
      }
      if (!this.patient.meetingTime) {
        errors.push("Ange mötestid");
      }
      if (!this.patient.dayOfWeek) {
        errors.push("Ange mötesdag");
      }
      return errors;
    },
    validateNumber() {
      let phoneUtil = PhoneNumberUtil.getInstance();
      let number;
      if (this.patient.mobile && this.patient.mobile.length > 1) {
        number = phoneUtil.parse(this.patient.mobile, "SE");
        if (phoneUtil.isValidNumber(number)) {
          this.patient.mobile = phoneUtil.format(
            number,
            PhoneNumberFormat.E164
          );
          return true;
        }
      }
      return false;
    },
  },
};
</script>
