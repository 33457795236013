import { apiPostRequest, apiGetRequest } from "./api"; // eslint-disable-line

export default {
    state: {
        userList: [],
        organisationList: [],
        roles: [],
        availableUsers: []
    },
    mutations: {
        setUsers(state, users) {
            state.userList = users;
        },
        setOrganisations(state, organisations) {
            state.organisationList = organisations;
        },
        setRoles(state, roles) {
            state.roles = roles;
        },
        setAvailableUsers(state, users) {
            state.availableUsers = users;
        }
    },
    actions: {
        async loadUsers({ commit }) {
            await apiGetRequest("users").then(data => {
                commit("setUsers", data);
            });
        },
        async loadOrganisations({ commit }) {
            await apiGetRequest("organisations").then(data => {
                commit("setOrganisations", data);
            });
        },
        async loadRoles({ commit }) {
            await apiGetRequest("roles").then(data => {
                commit("setRoles", data);
            });
        },
        async createUser({ commit }, data) { // eslint-disable-line
            await apiPostRequest("users/create", data);
        },
        async updateUser({ commit }, data) { // eslint-disable-line
            await apiPostRequest("users/update", data);
        },
        async removeUser({ commit }, id) { // eslint-disable-line
            await apiPostRequest("users/delete/" + id);
        },
        async createOrg({ commit }, data) { // eslint-disable-line
            await apiPostRequest("organisations", data);
        },
        async loadAvailableUsers({ commit }) {
            await apiGetRequest("userlist/patient").then((data) => {
                commit("setAvailableUsers", data);
            });
        }
    },
    getters: {
        userList: state => state.userList,
        organisationList: state => state.organisationList,
        roles: state => state.roles,
        getAvailableUsers: state => state.availableUsers
    }
}