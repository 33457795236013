import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import _ from 'lodash';

const dateNow = (date) => {
    return _.capitalize(format(date, "iiii yyyy-MM-dd", { locale: sv }));
}

const formatTime = (date) => {
    return format(date, "H:mm");
}

const dayName = (date) => {
    return _.capitalize(format(date, "iiii", { locale: sv }));
}

const timeDate = (date) => {
    return format(date, "H:mm d MMMM yyyy", { locale: sv });
}

const dateSpan = (date1, date2) => {
    let dateStart = _.capitalize(format(date1, "iiii d MMMM", { locale: sv }));
    let dateEnd = _.capitalize(format(date2, "iiii d MMMM", { locale: sv }));

    return dateStart + " - " + dateEnd;
}

export {
    dateNow,
    formatTime,
    dayName,
    timeDate,
    dateSpan
}

