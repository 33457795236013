<template>
  <div>
    <div>
      <p class="font-bold py-2 text-xl">{{ $t("graph.trend."+id) }}</p>
      <div>
        <canvas width="100" height="15" :id="'trend'+id"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";
export default {
  name: "BaseTrendGraph",
  props: {
    trend: Object,
    currentWeek: Number,
    id: Number,
  },
  data() {
    return {
      index: null,
    };
  },
  mounted() {
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (
          chart.config.options.chartArea &&
          chart.config.options.chartArea.backgroundColor
        ) {
          var ctx = chart.chart.ctx;
          var chartArea = chart.chartArea;
          ctx.save();
          ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
          ctx.fillRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top
          );
          ctx.restore();
        }
      },
    });

    new Chart("trend" + this.id, {
      type: "line",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "Värde",
            data: this.values,
            backgroundColor: ["rgba(0, 0, 0, 0)"],
            borderColor: ["#DF007F"],
            borderWidth: 2,
            lineTension: false,
            pointLabelFontSize: 4,
            fill: false,
            pointBorderColor: "RGB(223,0,127)",
            pointBackgroundColor: "RGB(223,0,127)",
            pointRadius: 2,
            pointHitRadius: 4,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          backgroundColor: "#fff",
          titleFontColor: "#000",
          bodyFontColor: "#000",
          displayColors: false,
          borderColor: "RGB(223,0,127)",
          borderWidth: 1,
        },
        chartArea: {
          backgroundColor: "RGB(243,243,243)",
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                padding: 5,
              },
              gridLines: {
                color: "white",
                lineWidth: 1,
                drawTicks: false,
              },
            },
          ],
          xAxes: [
            {
              offset: true,
              gridLines: {
                borderDash: [3, 4],
                color: "RGB(207,207,207)",
                lineWidth: 1,
                drawTicks: false,
              },
              ticks: {
                padding: 10,
                autoSkip: true,
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0
              },
            },
          ],
        },
      },
    });
  },
  computed: {
    labels() {
      let labels = [];
      let weeks = Array(this.currentWeek + 1);
      for (let week = 1; week < weeks.length; week++) {
        labels.push("Vecka " + week);
      }
      return labels;
    },
    values() {
      let values = [];
      let weeks = Array(this.currentWeek + 1);
      for (let week = 1; week < weeks.length; week++) {
        let value = this.trend[week];
        values.push(value);
      }
      return values;
    },
  },
  methods: {

  },
};
</script>