<template>
  <div class="w-full bg-light-back px-2 sm:px-12 pb-12">
    <div class="py-2">
      <p class="font-bold text-2xl -mb-3">{{ $t('patientlist.list') }}</p>
      <p class="text-2xl text-base-gray-text">{{ $t('patientlist.baro') }}</p>
    </div>
    <div class>
      <div
        class="p-4 flex flex-col items-center bg-white sm:flex-row sm:justify-between sm:items-end"
      >
        <div class="w-2/3 my-2">
          <p class="text-base-gray-text">{{ $t('patientlist.searchpatient') }}</p>
          <div class="w-full flex rounded-md hover:shadow-md">
            <input
              type="text"
              class="appearance-none w-full border border-search-button rounded-l-md p-1 focus:outline-none"
              placeholder="Namn, personnummer, patient-ID"
              v-model="searchInput"
              @keyup.enter="searchPatients()"
            />
            <div
              class="bg-search-button p-1 rounded-r-md flex items-center justify-center w-10 cursor-pointer select-none"
              @click="searchPatients()"
            >
              <img class="w-5" src="@/assets/images/search.png" alt srcset />
            </div>
          </div>
        </div>
        <div class="my-2 w-2/3 sm:text-right">
          <button
            class="bg-green-500 px-8 py-2 rounded-lg text-white"
            @click="createNewPatient"
          >{{ $t('patientlist.newpatient') }}</button>
        </div>
      </div>
      <div class="p-4 bg-white">
        <table class="w-full text-left" v-if="loaded">
          <tr class="text-base-gray-text border-b-2 border-pink-600">
            <th></th>
            <th class="p-2 font-thin">{{ $t('patientlist.name') }}</th>
            <th class="p-2 font-thin">{{ $t('patientlist.meetingtime') }}</th>
            <th class="p-2 font-thin">{{ $t('patientlist.therapist') }}</th>
            <th class="p-2 font-thin"></th>
          </tr>
          <tr
            v-for="(patient, index) in patients"
            :key="index"
            class="border-b-2 cursor-pointer hover:bg-gray-100"
          >
            <td class="text-center"><font-awesome-icon icon="edit" class="text-xl" @click="editPatient(patient)" /></td>
            <td class="p-2 text-sm" @click="$router.push({ name: 'patientOverview', params: { patientId: patient.id } })">{{ patient.name }}</td>
            <td
              class="p-2 text-sm"
              @click="$router.push({ name: 'patientOverview', params: { patientId: patient.id } })"
            >{{ $t('patientList.days.'+patient.dayOfWeek) }}, {{ formatTime(patient.meetingTime) }}</td>
            <td class="p-2 text-sm">{{ patient.therapist }}</td>
            <td class="p-2 text-sm" @click="$router.push({ name: 'patientOverview', params: { patientId: patient.id } })">
              <img src="@/assets/images/arrow-right.png" class="w-3 float-right mr-2" alt srcset />
            </td>
          </tr>
        </table>
        <div v-else class="flex justify-center">
          <font-awesome-icon icon="circle-notch" class="m-2 text-blue" spin size="2x" />
        </div>
      </div>
    </div>
    <modal height="auto" :scrollable="true" name="editPatient">
      <EditPatient @loadPatientList="loadPatientList" :patientId="patientId" :availableUsers="availableUsers"/>
    </modal>
  </div>
</template>

<script>
import { formatTime } from "@/utils/dateformat.js";
import EditPatient from "@/components/EditPatient.vue";
export default {
  name: "PatientList",
  components: {
    EditPatient,
  },
  data() {
    return {
      search: null,
      loaded: false,
      searchInput: "",
      patientId: null,
      availableUsers: null
    };
  },
  created() {
    this.loadPatientList();
    this.loadUserList();
  },
  computed: {
    patientList() {
      return this.$store.getters.getPatientList;
    },
    patients() {
      return this.patientList.data;
    },
  },
  methods: {
    loadPatientList() {
      this.$store.dispatch("loadPatientList").then(() => {
        this.loaded = true;
      });
    },
    loadUserList(){
      this.$store.dispatch("loadAvailableUsers").then(() => {
        this.availableUsers = this.$store.getters.getAvailableUsers;
      });
    },
    formatTime(time) {
      return formatTime(new Date(time.slice(0, -1)));
    },
    createNewPatient() {
      this.patientId = null;
      this.$modal.show("editPatient");
    },
    searchPatients() {
      if(this.searchInput == "") {
        this.loadPatientList();
        return;
      }

      this.loaded = false;
      this.$store.dispatch("searchPatientList", this.searchInput).then(() => {
        this.loaded = true;
      });
    },
    editPatient(patient) {
      this.patientId = patient.id;
      this.$modal.show("editPatient");
    }
  },
};
</script>

<style>
</style>