<template>
  <div id="app" class="select-none bg-base-back">
    <div class="container mx-auto">
      <Header v-if="authenticated" class="print-hide" />
      <router-view id="view" @click="closeSidebar()"></router-view>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import { isPast } from "date-fns";
export default {
  name: "App",
  mounted() {
    this.checkJwtExpiration();
  },
  components: {
    Header,
  },
  computed: {
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    checkJwtExpiration() {
      return this.$store
        .dispatch("checkJwtExpiration")
        .then((exp) => {
          let d = new Date(exp * 1000);
          if (isPast(d)) {
            this.$store.dispatch("logout");
          }
        })
        .catch(() => {});
    },
    closeSidebar() {
      this.$store.dispatch("closeSidebar");
    }
  },
};
</script>

<style>
</style>
