import Vue from "vue";
import VueRouter from "vue-router";

import PatientList from "@/components/PatientList.vue";
import PatientOverview from "@/components/PatientOverview.vue";
import Login from "@/components/Login.vue"
import LoginVerified from "@/components/LoginVerified.vue"
import Logout from "@/components/Logout.vue"
import AdminUsers from "@/components/AdminUsers.vue"
import AdminOrg from "@/components/AdminOrg.vue"
import PdfWeek from "@/components/PdfWeek.vue"

Vue.use(VueRouter);


let routes = [
    {
        path: "/login",
        name: "login",
        component: Login,
        props: { errorMsg: null }
    },
    {
        path: "/loginVerified",
        name: "loginVerified",
        component: LoginVerified
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout
    },
    {
        path: "/list",
        name: "list",
        component: PatientList,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/overview/patient/:patientId",
        name: "patientOverview",
        component: PatientOverview,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/users",
        name: "users",
        component: AdminUsers,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/organisations",
        name: "organisations",
        component: AdminOrg,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/pdf/week/:patientId/:week",
        name: "pdfWeek",
        component: PdfWeek,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "*",
        redirect: "/list"
    }
];

export const router = new VueRouter({
    mode: "history",
    routes: routes
});

router.beforeEach((to, from, next) => {
    const authRequired = to.meta.requireAuth && to.meta.requireAuth == true;
    const loggedIn = localStorage.getItem("user-token");

    if (authRequired && !loggedIn) {
        return next("/login");
    }

    next();
});