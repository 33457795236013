<template>
  <div class="w-full bg-light-back px-2 sm:px-12 pb-12">
    <div class="py-2">
      <p class="font-bold text-2xl -mb-3">{{ $t('users.userlist') }}</p>
      <p class="text-2xl text-base-gray-text"></p>
    </div>
    <div class="w-full p-2 bg-white">
      <div class="my-2 flex justify-end p-4">
        <button
          class="bg-green-500 px-8 py-2 rounded-lg text-white"
          @click="editUser(null)"
        >{{ $t('users.newuser') }}</button>
      </div>
      <div class="p-4">
        <table v-if="loaded" class="w-full text-left">
          <tr class="text-base-gray-text border-b-2 border-pink-600">
            <th>{{ $t('users.id') }}</th>
            <th>{{ $t('users.name') }}</th>
            <th>{{ $t('users.title') }}</th>
            <th>{{ $t('users.phone') }}</th>
            <th>{{ $t('users.organisation') }}</th>
            <th>{{ $t('users.hsaid') }}</th>
          </tr>
          <tr
            v-for="(user, index) in users"
            :key="index"
            class="border-b-2 cursor-pointer hover:bg-gray-100"
            @click="editUser(user)"
          >
            <td>{{ user.id }}</td>
            <td>{{ user.first_name }} {{ user.last_name }}</td>
            <td>{{ user.title }}</td>
            <td>{{ user.phone }}</td>
            <td>{{ user.organisation }}</td>
            <td>{{ user.hsaid }}</td>
          </tr>
        </table>
        <div v-else class="flex justify-center">
          <font-awesome-icon icon="circle-notch" class="m-2 text-blue" spin size="2x" />
        </div>
      </div>
    </div>
    <modal height="auto" :scrollable="true" name="editUser">
      <EditUser
        @loadUserList="loadUserList"
        :initialUser="user"
        :organisationList="organisationList"
        :roles="roles"
      />
    </modal>
  </div>
</template>

<script>
import EditUser from "@/components/EditUser";
export default {
  name: "AdminUsers",
  components: {
    EditUser,
  },
  data() {
    return {
      userList: [],
      loaded: false,
      user: null,
      organisationList: [],
      roles: null,
    };
  },
  created() {
    this.loadUserList();
    this.loadOrganisations();
    this.loadRoles();
  },
  methods: {
    loadUserList() {
      this.$store.dispatch("loadUsers").then(() => {
        this.userList = this.$store.getters.userList;
        this.loaded = true;
      });
    },
    loadOrganisations() {
      if (this.$store.getters.isAdmin) {
        this.$store.dispatch("loadOrganisations").then(() => {
          this.organisationList = this.$store.getters.organisationList;
        });
      }
    },
    loadRoles() {
      this.$store.dispatch("loadRoles").then(() => {
        this.roles = this.$store.getters.roles;
      });
    },
    editUser(user) {
      this.user = user;
      this.$modal.show("editUser");
    },
  },
  computed: {
    users() {
      return this.userList.data;
    },
  },
};
</script>