import axios from 'axios'
import { apiPostRequest } from './api'

const requestSithsLogin = () => {
    return apiPostRequest('session/createWithSiths', {})
}

const verifySithsRequest = (data) => {
    return apiPostRequest('session/setSession', data)
}

const logoutRequest = (data) => {
    return apiPostRequest('session/logout', data)
}

export default {
    state: {
        token: localStorage.getItem("user-token") || null,
        data: localStorage.getItem('user-data') !== null ? JSON.parse(localStorage.getItem('user-data')) : null,
        sessionId: null,
        userLoggedIn: false,
    },
    mutations: {
        setLoginStatus(state, value) {
            state.userLoggedIn = value
        },
        setUserData(state, data) {
            state.data = data
            localStorage.setItem('user-data', JSON.stringify(data))
        },
        loginSuccess(state, token) {
            localStorage.setItem('user-token', token)
            state.token = token
        },
        logout(state) {
            delete axios.defaults.headers.common['Authorization']

            localStorage.removeItem('user-token')
            localStorage.removeItem('user-data')
            state.sessionId = null
            state.token = null
            state.data = null
        }
    },
    actions: {
        async loginSiths({ commit }) { // eslint-disable-line
            delete axios.defaults.headers.common['Authorization']

            return requestSithsLogin()
                .then(data => {
                    return data
                })
        },
        async verifySithsLogin({ commit }, sessionId) {
            const data = {
                authtype: 'siths',
                grandidsession: sessionId
            }

            return await verifySithsRequest(data)
                .then(data => {
                    let jwt = JSON.parse(atob(data.token.split('.')[1]))

                    let userId = jwt.IdentityId
                    let organisationId = jwt.OrganisationId
                    let organisationName = jwt.OrganisationName
                    let lastname = jwt.LastName
                    let firstname = jwt.FirstName
                    let roles = jwt.Roles

                    axios.defaults.headers.common['Authorization'] = `Bearer: ${data.token}`

                    commit('setUserData', {
                        firstname: firstname,
                        lastname: lastname,
                        id: userId,
                        organisationId: organisationId,
                        organisationName: organisationName,
                        roles: roles
                    })
                    commit('loginSuccess', data.token)

                    return data
                })
                .catch(error => error)
        },
        async logout({ commit, state }) {
            if (!state.sessionId) {
                commit('logout')
                return true
            }

            const data = {
                sessionId: state.sessionId
            }

            return await logoutRequest(data)
                .then(() => {
                    commit('logout')
                })
                .catch(error => {
                    commit('logout')
                    return error
                })
        },
        checkJwtExpiration({ state }) {
            let jwt =
                state.token !== null
                    ? JSON.parse(atob(state.token.split(".")[1]))
                    : null;
            if (jwt !== null) {
                return Promise.resolve(jwt.exp);
            }
            return Promise.reject(false);
        }
    },
    getters: {
        isAuthenticated: state => state.token !== null ? true : false,
        userData: state => state.data,
        isDev: state => state.data !== null && state.data.roles != null && state.data.roles.indexOf('DEV') > -1 ? true : false,
        isAdmin: state => state.data !== null && state.data.roles != null && state.data.roles.indexOf('ADMIN') > -1 ? true : false,
        isLocalAdmin: state => state.data !== null && state.data.roles != null && state.data.roles.indexOf('LOCALADMIN') > -1 ? true : false,
    }
}
