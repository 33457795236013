<template>
  <div class="relative">
    <div class="absolute" style="top: 20px; left: 20px;">
      <img
        class="h-5 cursor-pointer"
        src="@/assets/images/arrow-left.png"
        @click="$router.push({ name: 'list' })"
      />
    </div>
    <div
      id="weeks"
      v-if="loaded"
      class="w-full bg-light-back px-2 sm:px-12 pb-12"
    >
      <div class="py-2">
        <p class="font-bold text-2xl -mb-3 flex items-center">
          {{ patient.firstName }} {{ patient.lastName }} -
          <span class="font-normal text-base italic text-base-gray-text ml-2">{{
            formatPid(patient.nationalId)
          }}</span>
        </p>
        <p class="text-2xl text-base-gray-text">{{ $t("overview.baro") }}</p>
      </div>
      <div class="flex">
        <div
          :class="selectedTab == 'week' ? 'selected' : ''"
          @click="selectTab('week')"
          class="py-2 w-48 text-center cursor-pointer"
        >
          {{ $t("overview.weeklycard") }}
        </div>
        <div
          :class="selectedTab == 'trend' ? 'selected' : ''"
          @click="selectTab('trend')"
          class="py-2 w-48 text-center cursor-pointer"
        >
          {{ $t("overview.trend") }}
        </div>
      </div>

      <div class="w-full p-2 bg-white" v-if="selectedTab == 'week'">
        <div class="px-2 flex justify-between items-center sm:px-8">
          <div class="flex items-center">
            <div class="flex items-center">
              <div>
                <img
                  class="h-5 cursor-pointer"
                  src="@/assets/images/arrow-left.png"
                  v-if="this.week == 1"
                />
                <img
                  class="h-5 cursor-pointer"
                  src="@/assets/images/arrow-left-click.png"
                  v-if="this.week != 1"
                  @click="prevWeek()"
                />
              </div>
              <div class="text-2xl font-bold p-4">
                {{ $t("overview.week") }} {{ week }}
              </div>
              <div>
                <img
                  class="h-5 cursor-pointer"
                  src="@/assets/images/arrow-right.png"
                  v-if="this.week == this.patientWeek.currentWeek"
                />
                <img
                  class="h-5 cursor-pointer"
                  src="@/assets/images/arrow-right-click.png"
                  v-if="this.week != this.patientWeek.currentWeek"
                  @click="nextWeek()"
                />
              </div>
            </div>
            <div class="px-6 text-base-gray-text">{{ weekDates }}</div>
          </div>
          <div>
            <router-link
              :to="{
                name: 'pdfWeek',
                params: { week: week, patientId: patientId },
              }"
              class="bg-link-blue p-2 px-4 rounded-md font-bold text-white"
              target="_blank"
            >
              {{ $t("overview.print") }}
              <font-awesome-icon class="text-white ml-2" icon="print" />
            </router-link>
          </div>
        </div>
        <div v-if="loadedWeek">
          <div class="px-2 py-4 flex flex-col items-start sm:px-8">
            <p class="text-lg font-bold">{{ $t("overview.weekQuestion") }}:</p>
            <p class="text-sm text-base-gray-text">
              {{ $t("overview.before") }}
            </p>
          </div>
          <div class="px-2 flex items-start sm:px-8">
            <component
              :is="'BaseNumberSelect'"
              class="mr-10"
              v-bind:value.sync="beforeSelfHarm"
              :title="$t('overview.hurtYourself')"
              :initValue="overviewData.beforeSelfHarm"
            />
            <component
              :is="'BaseNumberSelect'"
              class="mr-10"
              v-bind:value.sync="beforeEndTherapy"
              :title="$t('overview.quit')"
              :initValue="overviewData.beforeEndTherapy"
            />
          </div>

          <div>
            <div v-for="(section, index) in patientWeek.responses" :key="index">
              <p
                v-if="section.title"
                class="-mb-6 px-2 sm:px-8 font-bold text-lg py-2"
              >
                {{ section.title }}
              </p>
              <div
                v-for="(question, i) in section.questions"
                :key="i"
                class="my-8 px-2 sm:px-8"
              >
                <component
                  :is="'BaseWeekGraph'"
                  :responses="question"
                  :id="parseInt(i)"
                />
              </div>
            </div>
          </div>

          <div class="px-2 py-4 flex flex-col items-start sm:px-8">
            <p class="text-lg font-bold">{{ $t("overview.weekQuestion") }}:</p>
            <p class="text-sm text-base-gray-text">
              {{ $t("overview.after") }}
            </p>
          </div>
          <div class="px-2 flex items-start sm:px-8">
            <component
              :is="'BaseNumberSelect'"
              class="mr-10"
              v-bind:value.sync="afterSelfHarm"
              :title="$t('overview.hurtYourself')"
              :initValue="overviewData.afterSelfHarm"
            />
            <component
              :is="'BaseNumberSelect'"
              class="mr-10"
              v-bind:value.sync="afterEndTherapy"
              :title="$t('overview.quit')"
              :initValue="overviewData.afterEndTherapy"
            />
          </div>
          <div class="px-2 pt-16 pb-8 flex items-center sm:px-8">
            <img
              @click="scrollTo()"
              class="w-8 mr-4 rounded-full cursor-pointer"
              src="@/assets/images/circleArrow.png"
              id="upCircle"
            />
            <span
              @click="scrollTo()"
              class="text-link-blue font-bold cursor-pointer"
              >{{ $t("overview.top") }}</span
            >
          </div>
        </div>
        <div v-else class="flex justify-center">
          <font-awesome-icon
            icon="circle-notch"
            class="m-2 text-blue"
            spin
            size="2x"
          />
        </div>
      </div>

      <div class="w-full p-2 bg-white" v-if="selectedTab == 'trend'">
        <div v-if="loadedTrend">
          <div class="text-2xl font-bold p-4">
            {{ $t("overview.trend") }}
            <span class="text-base italic text-base-gray-text font-light">{{
              $t("overview.treatmentStart")
            }}</span>
          </div>
          <div>
            <div
              v-for="(tre, index) in trend.trends"
              :key="index"
              class="px-2 py-5 flex flex-col sm:px-8"
            >
              <component
                :is="'BaseTrendGraph'"
                :trend="tre"
                :currentWeek="trend.currentWeek"
                :id="parseInt(index)"
              ></component>
            </div>
          </div>
        </div>
        <div v-else class="flex justify-center">
          <font-awesome-icon
            icon="circle-notch"
            class="m-2 text-blue"
            spin
            size="2x"
          />
        </div>
      </div>
    </div>
    <div v-else class="w-full bg-light-back flex justify-center p-4">
      <font-awesome-icon
        icon="circle-notch"
        class="m-2 text-blue"
        spin
        size="2x"
      />
    </div>
    <div>
      <div v-if="saved" class="flex items-center">
        <img class="m-2 w-6" src="@/assets/images/greenCircle.png" />
        <span class="text-sm font-bold"
          >{{ $t("overview.lastSave") }}: {{ formatDate(saved) }}</span
        >
      </div>
      <div v-else-if="saved === false" class="flex items-center">
        <img class="m-2 w-6" src="@/assets/images/redCircle.png" />
        <span class="text-sm font-bold">{{ $t("overview.saveError") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Personnummer from "personnummer";
import { parseISO } from "date-fns";
import { timeDate, dateSpan } from "@/utils/dateformat.js";
export default {
  name: "PatientOverview",
  data() {
    return {
      patientId: this.$route.params.patientId,
      selectedTab: "week",
      week: null,
      loaded: false,
      loadedWeek: false,
      beforeSelfHarm: null,
      beforeEndTherapy: null,
      afterSelfHarm: null,
      afterEndTherapy: null,
      patientWeek: null,
      saved: null,
      overviewData: null,
      weekError: null,
      loadedTrend: false,
      trend: null,
      patient: null,
    };
  },
  watch: {
    beforeSelfHarm() {
      this.saveTherapy();
    },
    beforeEndTherapy() {
      this.saveTherapy();
    },
    afterSelfHarm() {
      this.saveTherapy();
    },
    afterEndTherapy() {
      this.saveTherapy();
    },
  },
  created() {
    this.loadWeek();
    this.loadTrend();
    this.loadPatient();
  },
  computed: {
    weekDates() {
      return this.patientWeek
        ? dateSpan(
            parseISO(this.patientWeek.startDate),
            parseISO(this.patientWeek.endDate)
          )
        : null;
    },
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
    prevWeek() {
      if (this.week > 1) {
        this.loadedWeek = false;
        this.week--;
        this.loadWeek();
      }
    },
    nextWeek() {
      if (this.patientWeek.currentWeek > this.week) {
        this.loadedWeek = false;
        this.week++;
        this.loadWeek();
      }
    },
    loadWeek() {
      this.weekError = false;
      let params =
        this.week !== null ? [this.patientId, this.week] : [this.patientId];
      this.$store
        .dispatch("loadWeek", params)
        .then(() => {
          this.patientWeek = this.$store.getters.getPatientWeek;
          this.week = this.patientWeek.weekNumber;

          this.loadData();
        })
        .catch(() => {
          this.weekError = true;
        });
    },
    loadTrend() {
      this.$store
        .dispatch("loadTrend", this.patientId)
        .then(() => {
          this.trend = this.$store.getters.getTrend;
          this.loadedTrend = true;
        })
        .catch(() => {
          this.loadedTrend = false;
        });
    },
    loadPatient() {
      this.$store.dispatch("loadPatient", this.patientId).then(() => {
        this.patient = this.$store.getters.getPatient;
      });
    },
    saveTherapy() {
      let data = {
        week: this.week,
        patientId: this.patientId,
        beforeSelfHarm: this.beforeSelfHarm,
        beforeEndTherapy: this.beforeEndTherapy,
        afterSelfHarm: this.afterSelfHarm,
        afterEndTherapy: this.afterEndTherapy,
      };
      this.$store
        .dispatch("saveTherapy", data)
        .then(() => {
          this.saved = new Date();
        })
        .catch((errors) => {
          this.saved = false;
          alert("Couldn't save therapy! \r" + errors);
        });
    },
    loadData() {
      this.overviewData = [];
      this.$store.dispatch("loadData", [this.patientId, this.week]).then(() => {
        this.overviewData = this.$store.getters.getOverviewData;
        this.loadedWeek = true;
        this.loaded = true;
      });
    },
    formatPid(pid) {
      return pid ? Personnummer.parse(pid).format() : "";
    },
    scrollTo() {
      this.$scrollTo("#weeks", 1000, { easing: "ease", cancelable: false });
    },
    formatDate(date) {
      return timeDate(date);
    },
  },
};
</script>
<style scoped>
.selected {
  border-bottom: 4px solid rgba(204, 10, 122, 1);
  background-color: white;
}
#upCircle {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
</style>
