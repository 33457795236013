<script>
export default {
  name: "LoginVerified",
  mounted() {
    this.$store
      .dispatch("verifySithsLogin", this.$route.query.grandidsession)
      .then(() => {
        this.$router.replace("/list");
      });
  },
  render(createElement) {
    return createElement("div");
  },
};
</script>