<template>
  <div class="flex flex-col p-8 bg-base-back">
    <div class="bg-white p-4">
      <div class="flex items-center">
        <label for="firstName" class="font-bold"
          >{{ $t("users.firstname") }}:</label
        >
        <input
          type="text"
          class="border-b-2 m-1 w-full"
          id="firstName"
          v-model="user.first_name"
        />
      </div>
      <div class="flex items-center">
        <label for="lastName" class="font-bold"
          >{{ $t("users.lastname") }}:</label
        >
        <input
          type="text"
          class="border-b-2 m-1 w-full"
          id="lastName"
          v-model="user.last_name"
        />
      </div>
      <div class="flex items-center">
        <label for="title" class="font-bold">{{ $t("users.title") }}:</label>
        <input
          type="text"
          class="border-b-2 m-1 w-full"
          id="title"
          v-model="user.title"
        />
      </div>
      <div class="flex items-center">
        <label for="mobile" class="font-bold">{{ $t("users.phone") }}:</label>
        <input
          type="tel"
          class="border-b-2 m-1 w-full"
          id="mobile"
          v-model="user.phone"
        />
      </div>
      <div class="flex items-center">
        <label for="hsaid" class="font-bold">{{ $t("users.hsaid") }}:</label>
        <input
          type="text"
          class="border-b-2 m-1 w-full"
          id="hsaid"
          v-model="user.hsaid"
        />
      </div>
      <div class="flex items-center" v-if="$store.getters.isAdmin">
        <label for="organisation" class="font-bold"
          >{{ $t("users.organisation") }}:</label
        >
        <select
          type="text"
          class="border-b-2 m-1 w-full"
          id="organisation"
          v-model="user.organisation_id"
        >
          <option
            v-for="(org, index) in organisationList"
            :key="index"
            :value="org.id"
          >
            {{ org.name }}
          </option>
        </select>
      </div>
      <div class="flex flex-row items-center justify-between">
        <label for="roles" class="font-bold">{{ $t("users.role") }}:</label>
        <div v-for="(role, index) in roles" :key="index" class="flex flex-col items-center">
          <label :for="'roles' + role.id">{{ role.name }}</label>
          <input
            type="checkbox"
            class="border-b-2 m-1 w-full"
            :id="'roles' + role.id"
            :value="role.id"
            v-model="user.roles"
          />
        </div>
      </div>
      <div class="flex justify-center mt-8">
        <button
          class="bg-green-600 text-white rounded-lg px-6 py-2 mx-2"
          @click="updateUser()"
        >
          {{ $t("users.save") }}
        </button>
        <button
          v-if="this.user.id"
          class="bg-red-600 text-white rounded-lg px-6 py-2 mx-2"
          @click="removeUser()"
        >
          {{ $t("users.remove") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
export default {
  name: "EditUser",
  props: {
    initialUser: Object,
    organisationList: Array,
    roles: Array,
  },
  created() {
    this.user.roles = this.user.roles.map((role) => (role.id ? role.id : role));
  },
  data() {
    return {
      newUser: {
        firstName: "",
        lastName: "",
        phone: null,
        hsaid: "",
        id: null,
        title: "",
        organisation_id: null,
        roles: [],
      },
    };
  },
  computed: {
    user() {
      return this.initialUser ? this.initialUser : this.newUser;
    },
  },
  methods: {
    updateUser() {
      let errors = this.validateUser();
      if (errors.length > 0) {
        alert(errors.join("\n"));
      } else {
        if (this.user.id === null) {
          this.$store
            .dispatch("createUser", this.user)
            .then(() => {
              this.$modal.hide("editUser");
              this.$emit("loadUserList");
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        } else {
          this.$store
            .dispatch("updateUser", this.user)
            .then(() => {
              this.$modal.hide("editUser");
              this.$emit("loadUserList");
            })
            .catch((error) => {
              alert(error.response.data.message);
            });
        }
      }
    },
    removeUser() {
      if (
        confirm(
          "Är du säker på att du vill ta bort användaren med id: " +
            this.user.id
        )
      ) {
        this.$store.dispatch("removeUser", this.user.id).then(() => {
          this.$modal.hide("editUser");
          this.$emit("loadUserList");
        });
      }
    },
    validateUser() {
      let errors = [];
      if (!this.user.first_name) {
        errors.push("Förnamn saknas");
      }
      if (!this.user.last_name) {
        errors.push("Efternamn saknas");
      }
      if (!this.validateHsaId()) {
        errors.push("Felaktigt HSA-ID");
      }
      if (this.user.roles.length < 1) {
        errors.push("Ange minst en roll");
      }
      if (this.user.phone && this.user.phone.length > 1) {
        let valid = this.validateNumber();
        if (!valid) errors.push("Felaktigt telefonnummer");
      } else if (this.user.phone && this.user.phone.length == 1) {
        errors.push("Felaktigt telefonnummer");
      }
      return errors;
    },
    validateNumber() {
      let phoneUtil = PhoneNumberUtil.getInstance();
      let number;
      number = phoneUtil.parse(this.user.phone, "SE");
      if (phoneUtil.isValidNumber(number)) {
        this.user.phone = phoneUtil.format(number, PhoneNumberFormat.E164);
        return true;
      }
      return false;
    },
    validateHsaId() {
      let hsaid = this.user.hsaid.replaceAll(" ", "");
      const regex = /^SE[A-Z0-9]+-[A-Z0-9]+$/;
      let match = regex.exec(hsaid);
      if(match !== null) {
        this.user.hsaid = hsaid;
        return true;
      }
      return false;
    }
  },
};
</script>