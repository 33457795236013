import Vue from 'vue'
import patients from './patients'
import uiStore from './ui'
import userStore from './user'
import adminStore from './admin'

import * as types from './mutation-types'

const state = {
    info: {},
}

const mutations = {
    [types.GET_INFO_ASYNC.PENDING](state) {
        Vue.set(state.info, types.GET_INFO_ASYNC.loadingKey, true)
        Vue.set(state.info, [types.GET_INFO_ASYNC.errorKey], null)
    },

    [types.GET_INFO_ASYNC.SUCCESS](state, info) {
        state.info[types.GET_INFO_ASYNC.loadingKey] = false
        Vue.set(state.info, [types.GET_INFO_ASYNC.stateKey], info)
    },

    [types.GET_INFO_ASYNC.FAILURE](state, error) {
        state.info[types.GET_INFO_ASYNC.loadingKey] = false
        Vue.set(state.info, [types.GET_INFO_ASYNC.stateKey], null)
        Vue.set(state.info, [types.GET_INFO_ASYNC.errorKey], error)
    }
}

export default {
    state,
    mutations,
    getters: {
        pendingAsyncCall: state => state.info.apiPending,
    },
    modules: {
        patients: patients,
        ui: uiStore,
        user: userStore,
        admin: adminStore
    }
}