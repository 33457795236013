<template>
  <div>
    <div>
      <p class="font-bold py-2">{{ responses.meta.title }}</p>
      <div v-if="hasGraph">
        <canvas width="100" height="15" :id="'chart'+id"></canvas>
      </div>
      <table class="w-full text-left">
        <tr class="border-b font-bold text-gray-header">
          <th class="text-sm p-2 w-1/6">{{ $t('graph.day') }}</th>
          <th class="text-sm p-2 w-1/6">{{ responses.meta.valueText }}</th>
          <th
            class="text-sm p-2"
          >{{ responses.meta.comment ? responses.meta.commentText ? responses.meta.commentText : $t("graph.comment") : "" }}</th>
        </tr>
        <tr
          :class="index == ind ? 'bg-hover-pink border-l-4 border-circle-pink' : 'border-b'"
          v-for="(resp, ind) in responses.answers"
          :key="ind"
          class="text-sm"
        >
          <td class="p-2">{{ formatDay(resp.day) }}</td>
          <td
            class="p-2 font-bold"
          >{{ responses.meta.type == "yesno" ? (resp.value == null ? "" : resp.value == 1 ? $t("graph.yes") : $t("graph.no")) : resp.value }}</td>
          <td class="p-2">{{ resp.comment }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { dateNow, dayName } from "@/utils/dateformat.js";
import { parseISO } from "date-fns/esm";
import Chart from "chart.js";
export default {
  name: "BaseWeekGraph",
  props: {
    responses: Object,
    id: Number,
  },
  data() {
    return {
      index: null,
    };
  },
  mounted() {
    let index = null;
    let $vm = this;
    if (this.hasGraph) {
      Chart.pluginService.register({
        beforeDraw: function (chart) {
          if (
            chart.config.options.chartArea &&
            chart.config.options.chartArea.backgroundColor
          ) {
            var ctx = chart.chart.ctx;
            var chartArea = chart.chartArea;
            ctx.save();
            ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
            ctx.fillRect(
              chartArea.left,
              chartArea.top,
              chartArea.right - chartArea.left,
              chartArea.bottom - chartArea.top
            );
            ctx.restore();
          }
        },
      });

      new Chart("chart" + this.id, {
        type: "line",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.responses.meta.valueText,
              data: this.values,
              backgroundColor: ["rgba(0, 0, 0, 0)"],
              borderColor: ["#DF007F"],
              borderWidth: 1,
              lineTension: false,
              pointLabelFontSize: 4,
              fill: false,
              pointBorderColor: "RGB(223,0,127)",
              pointBackgroundColor: "RGB(223,0,127)",
              pointBorderWidth: 1,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 4,
              pointRadius: 4,
              pointHitRadius: 4,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          tooltips: {
            backgroundColor: "#fff",
            titleFontColor: "#000",
            bodyFontColor: "#000",
            displayColors: false,
            borderColor: "RGB(223,0,127)",
            borderWidth: 1,
          },
          chartArea: {
            backgroundColor: "RGB(243,243,243)",
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  suggestedMax: this.responses.meta.max
                    ? this.responses.meta.max
                    : this.responses.meta.options.length,
                  min: this.responses.meta.min,
                  stepSize: 1,
                  padding: 5,
                },
                gridLines: {
                  color: "white",
                  lineWidth: 1,
                  drawTicks: false,
                },
              },
            ],
            xAxes: [
              {
                offset: true,
                gridLines: {
                  borderDash: [3, 4],
                  color: "RGB(207,207,207)",
                  lineWidth: 1,
                  drawTicks: false,
                },
                ticks: {
                  padding: 10,
                },
              },
            ],
          },
          hover: {
            onHover: function (e, element) {
              if (element[0]) {
                $vm.index = element[0]._index;
              } else {
                $vm.index = null;
              }
            },
          },
        },
      });
      $vm.index = index;
    }
  },
  computed: {
    labels() {
      let labels = [];
      this.responses.answers.forEach((resp) => {
        labels.push(dayName(parseISO(resp.day)));
      });
      return labels;
    },
    values() {
      let values = [];
      this.responses.answers.forEach((resp) => {
        values.push(resp.value);
      });
      return values;
    },
    hasGraph() {
      switch (this.responses.meta.type) {
        case "range":
          return true;
        case "yesno":
          return false;
        case "multiradio":
          return true;
        case "text":
          return false;
        default:
          return false;
      }
    },
  },
  methods: {
    formatDay(day) {
      return dateNow(parseISO(day));
    },
  },
};
</script>