<template>
  <div>
    <div class="m-1">{{ title }}</div>
    <div class="flex">
      <div
        class="rounded-full w-10 h-10 m-1 flex items-center justify-center cursor-pointer"
        @click="changeValue(0)"
        :class="value == 0 ? 'bg-circle-pink text-white' : 'border border-base-gray-text'"
      >0</div>
      <div
        class="rounded-full w-10 h-10 m-1 flex items-center justify-center cursor-pointer"
        @click="changeValue(1)"
        :class="value == 1 ? 'bg-circle-pink text-white' : 'border border-base-gray-text'"
      >1</div>
      <div
        class="rounded-full w-10 h-10 m-1 flex items-center justify-center cursor-pointer"
        @click="changeValue(2)"
        :class="value == 2 ? 'bg-circle-pink text-white' : 'border border-base-gray-text'"
      >2</div>
      <div
        class="rounded-full w-10 h-10 m-1 flex items-center justify-center cursor-pointer"
        @click="changeValue(3)"
        :class="value == 3 ? 'bg-circle-pink text-white' : 'border border-base-gray-text'"
      >3</div>
      <div
        class="rounded-full w-10 h-10 m-1 flex items-center justify-center cursor-pointer"
        @click="changeValue(4)"
        :class="value == 4 ? 'bg-circle-pink text-white' : 'border border-base-gray-text'"
      >4</div>
      <div
        class="rounded-full w-10 h-10 m-1 flex items-center justify-center cursor-pointer"
        @click="changeValue(5)"
        :class="value == 5 ? 'bg-circle-pink text-white' : 'border border-base-gray-text'"
      >5</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseNumberSelect",
  data() {
    return {
      value: this.initValue
    };
  },
  created() {
    this.$emit("update:value", this.value);
  },
  watch: {
    value() {
      this.$emit("update:value", this.value);
    }
  },
  methods: {
    changeValue(val)
    {
      if(this.value == val){
        this.value = null;
      }else {
        this.value = val;
      }
    }
  },
  props: {
    title: String,
    initValue: Number
  }
};
</script>