<template>
  <div class="w-full h-full bg-white absolute left-0 top-0">
    <div class="relative h-full">
      <p v-if="patientLoaded">
        {{ patient.firstName }} {{ patient.lastName }}, {{ $t("pdf.week") }}
        {{ $route.params.week }}
      </p>
      <table
        id="table"
        class="text-xs bg-white text-center w-full"
        v-if="weekLoaded"
      >
        <tr>
          <th class="border border-black">{{ $t("pdf.day") }}</th>
          <th
            v-for="(header, index) in week.headers"
            :key="index"
            class="p-1 border border-black"
          >
            {{ header }}
          </th>
        </tr>
        <tr v-for="(days, day) in week.days" :key="day" class="p-1">
          <td class="border border-black w-16 h-16 font-bold">
            {{ formatDate(day) }}
          </td>
          <td class="border border-black" v-for="(ans, x) in days" :key="x">
            {{ ans.value }}
          </td>
        </tr>
      </table>
    </div>
    <div class="relative h-full bg-white">
      <table v-if="weekLoaded" class="text-xs bg-white text-center w-full">
        <tr>
          <th class="border border-black">{{ $t("pdf.day") }}</th>
          <th class="border border-black">{{ $t("pdf.comments") }}</th>
        </tr>
        <tr v-for="(other, day) in week.other" :key="day" class="p-1">
          <td class="border border-black w-16 h-16 font-bold">
            {{ formatDate(day) }}
          </td>
          <td class="border border-black">
            <ul>
              <li v-for="(answer, i) in week.days[day]" :key="i">
                <p v-if="answer.comment != null">
                  <span class="font-bold">{{ week.headers[i] }}:</span>
                  {{ answer.comment }}
                </p>
              </li>
              <li>
                <p v-if="other.value != null">
                  <span class="font-bold">Övrigt:</span>
                  {{ other.value }}
                </p>
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { dayName } from "@/utils/dateformat.js";
import { parseISO } from "date-fns/esm";
export default {
  name: "PdfWeek",
  data() {
    return {
      week: null,
      patient: null,
      patientLoaded: false,
      weekLoaded: false,
    };
  },
  created() {
    this.loadPdfWeek();
    this.loadPatient();
  },
  methods: {
    loadPdfWeek() {
      this.$store
        .dispatch("loadPdfWeek", [
          this.$route.params.patientId,
          this.$route.params.week,
        ])
        .then(() => {
          this.week = this.$store.getters.pdfWeek;
          this.weekLoaded = true;
        });
    },
    loadPatient() {
      this.$store
        .dispatch("loadPatient", this.$route.params.patientId)
        .then(() => {
          this.patient = this.$store.getters.getPatient;
          this.patientLoaded = true;
          setTimeout(() => {
            window.print();
          }, 1000);
        });
    },
    formatDate(day) {
      return dayName(parseISO(day));
    },
  },
};
</script>

<style type="text/css">
table {
  page-break-inside: auto;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
</style>
