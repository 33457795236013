<template>
  <div class="flex flex-col py-4 h-64">
    <div id="textEditor"></div>
  </div>
</template>
<script>
import Quill from "quill";
export default {
  name: "BaseTextEditor",
  data() {
    return {
      quill: null,
    };
  },
  props: {
    text: String,
  },
  mounted() {
    this.loadQuill();
  },
  methods: {
    loadQuill() {
      var toolbarOptions = [
        ["bold", "italic", "underline", "strike", "clean", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
      ];

      this.quill = new Quill("#textEditor", {
        modules: {
          toolbar: toolbarOptions,
        },
        placeholder: "",
        theme: "snow",
      });
      this.quill.root.innerHTML = this.text;

      this.quill.on("text-change", () => {
        this.updateText();
      });
    },
    updateText() {
      this.$emit("update:value", this.quill.root.innerHTML);
    },
  },
};
</script>